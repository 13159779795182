.camping-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 50px 20px;
    background-color: #f5f5f5; /* Light earthy tone */
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 50px;;
  }
  
  .camping-content {
    flex: 1;
    min-width: 300px;
    padding: 20px;
  }
  
  .camping-content h2 {
    font-size: 2rem;
    color: #B46F54; /* Forest green */
    margin-bottom: 15px;
  }
  
  .camping-content p {
    font-size: 1.1rem;
    color: #333;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .camping-features {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .camping-features li {
    font-size: 1rem;
    color: #555;
    margin: 10px 0;
    position: relative;
    padding-left: 20px;
  }
  
  .camping-features li:before {
    content: "✓";
    color: #2a5e2a;
    position: absolute;
    left: 0;
  }

  .button-div {
    display: flex;
    justify-content: center;
  }

  
  .cta-button {
    background-color: #0d172a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #1e421e; /* Darker green */
  }
  
  .camping-image {
    flex: 1;
    min-width: 300px;
    text-align: center;
  }
  
  .camping-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .camping-section {
      flex-direction: column;
      text-align: center;
    }
  
    .camping-image {
      margin-top: 20px;
    }
  }