/* Sidebar.css */

body {
  font-family: "Helvetica Neue", Arial,sans-serif;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  padding-bottom: 2rem;
}

a {
  text-decoration: none;
  color: #000000;
}

.sidebar {
  text-align: left;
  position: fixed;
  opacity: 95%;
  left: 0rem;
  top: 0;
  z-index: 50;
  height: 100%;
  width: 300px;
  padding-top: 8rem;
  padding-left: 3rem;
  background-color: #F6F5F8;
  backdrop-filter: blur(80px); /* Apply the blur effect */
  -webkit-backdrop-filter: blur(70px); /* For Safari support */

}

.cart-icon2 {
  width: 2.5rem;
  margin-left: 0;
}
